.dashboard-header {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  width: 500px;
  margin: 0 auto;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.176px;
}

.acc-status {
  font-weight: 700;
}

.configuration-container {
  width: 600px;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.configure-tab {
  border: 1px solid rgba(69, 69, 69, 0.5);
  padding: 23px 20px;
}

.outline-success {
  border-color: #4742ec;
}

.outline-fail {
  border-color: rgba(69, 69, 69, 0.5);
}

.box-header {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.configured-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.configure-metadata-1 {
  width: 170px;
}

.configure-metadata-2 {
  width: 145px;
}

.configure-metadata-3 {
  width: 156px;
}

.configure-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.configure-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
}

.configure-props {
  color: rgba(0, 0, 0, 0.7);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.configure-value {
  color: rgba(69, 69, 69, 0.5);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.action-btn {
  color: #fff;
  text-decoration: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  border-radius: 2px;
  background: linear-gradient(90deg, #4742ec 0%, #1a16de 92.34%);
  color: #fff;
  font-family: DM Sans;
  font-size: 13.942px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.auth-props {
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.auth--2 {
  margin-top: 10px;
}

.green-dot {
  background: #16d16d;
}

.red-dot {
  background: #ef5858;
}

@media(max-width: 600px) {
  .configuration-container {
    width: auto;
    padding: 5px 10px;
  }
}

@media(max-width: 560px) {

  .box-header,
  .configure-props,
  .auth-props {
    font-size: 14px;
  }

  
}

@media(max-width: 520px) {
  .configure-tab {
    padding: 10px 14px;
  }

  .configure-dot {
    width: 5px;
    height: 5px;
  }

  .configure-status {
    gap: 4px;
  }

  .dashboard-header {
    width: auto;
    text-align: center;
    margin: 10px;
  }
}