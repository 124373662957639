.configured_notificaiton {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #454545;
  margin-top: 25px;
  margin-bottom: 6px;
}

.notificaiton-received {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(69, 69, 69, 0.6);
  margin-bottom: 38px;
}

.notifying--card {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 14px 20px;
  margin: 20px 40px;
}

.notifying--card > div:first-child {
  display: flex;
  align-items: center;
}

.notifying--card > div:first-child > div:first-child {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
}

.notifying--card > div:last-child {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.notifying--card > div:last-child span {
  text-decoration: underline;
}

.notifying--card > div.mobile {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
}

.edit-notification {
  margin: 10px 40px;
  text-align: end;
}

.edit-notification button {
  background: none;
  border: none;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  color: #5652e9;
  cursor: pointer;
}
