@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

.select-font {
  font-family: "Lato", sans-serif;
}

.top-bar {
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  padding: 20px 72px;
  background: #d2d2f0;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 24px 80px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

.nav-hams-menu {
  display: none;
  cursor: pointer;
}

.nav-center-menu {
  display: flex;
  gap: 32px;
}

.hams-sytle {
  width: 48px;
  height: 48px;
}

.nav-center-menu a {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.nav-center-menu a:hover {
  text-decoration: underline;
}

.nav-end {
  display: flex;
  align-items: center;
  gap: 8px;
}

.get-start-btn-cont {
  margin-right: 16px;
}

.get-start-btn-cont button {
  color: #0b0992;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  border-radius: 100px;
  border: 1px solid #0b0992;
  padding: 8px 16px;
  background: transparent;
  cursor: pointer;
}

.hero-section {
  margin-top: 60px;
  position: relative;
}

/* .background-box {
  border-radius: 8px;
  background: rgba(210, 210, 240, 0.4);
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -1;
  width: 534px;
  height: 83px;
} */

.hero-container {
  display: flex;
  justify-content: space-around;
  gap: 120px;
  align-items: center;
  margin: 30px 110px;
}

.hero-title {
  color: #000021;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 620px;
  margin-bottom: 18px;
}

.hero-text {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  text-align: start;
  /* 31.392px */
  width: 620px;
  margin-bottom: 60px;
}

.hero-img img {
  width: 680px;
}

.hero-btn {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #0b0992;
  background: #0b0992;
  box-shadow: 4px 8px 5.2px 0px rgba(210, 209, 231, 0.3),
    1px 3px 1.1px 0px rgba(210, 209, 231, 0.5),
    4px 8px 4px 0px rgba(210, 209, 231, 0.2) inset;
  color: #fefefe;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.arrows {
  width: 32px;
  height: 32px;
}

.hero-btn:hover {
  gap: 16px;
  border: 1px solid #2422b7;
  background: #2422b7;
}

.hero-footer-text {
  margin-top: 90px;
  margin-bottom: 25px;
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hero-sub-para {
  color: #9ba6e7;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.separator {
  height: 1px;
  background: #ddd;
  margin: 30px 80px;
  margin-top: 60px;
  margin-bottom: 20px;
}

.about-me {
  padding: 30px 80px;
}

.about-heading {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.about-me-para {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.about-separator {
  margin-top: 20px;
}

.strategy-section {
  margin-top: 60px;
}

.strategy-container {
  display: flex;
  margin: 20px 80px;
  margin-bottom: 40px;
}

.strategy-container > div:first-child {
  text-align: center;
  display: flex;
}

.strategy-container img {
  width: 720px;
}

.strategy-title {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
}

.strategy-content {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.working-how {
  color: #000021;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.regular-paras-for-work {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.how-works-specific {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.8%;
}

.work-ethics-heading {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.8%;
}

.work-ordered-list {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.work-container-support {
  margin: 20px 80px;
  margin-bottom: 30px;
}

.early-bird-offer {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 45px;
}

.offer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 80px;
  gap: 100px;
}

.offer-cards {
  display: flex;
  gap: 48px;
  margin-bottom: 36px;
}

.card-details div {
  color: #000021;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.8%;
  /* 47.088px */
}

.offer-text {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 48px;
}

.offer-bird,
.offer-img {
  flex: 1;
}

.offer-img img {
  width: 500px;
}

.footer-class {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px 80px;
  background: #d2d2f0;
  margin-top: 60px;
}

.footer-logo {
  width: 140px;
}

.footer-middle {
  display: flex;
  gap: 22px;
}

.social-icons {
  display: flex;
  gap: 8px;
}

.footer-middle a {
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

/* Trade Like Jonah */
.jonah-trade-styles {
  position: relative;
}

.jonah-trade-styles::before {
  content: '';
  width: 150%;
  height: 80px;
  background: rgba(210, 210, 240, 0.4);
  position: absolute;
  top: -14px;
  right: -10px;
  z-index: -1;
  border-radius: 8px;
}

/* Mobile Nav */
.mobile-nav {
  position: fixed;
  width: 100vw;
  width: -webkit-fill-available;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* justify-content: space-between; */
}

.mobile-top-bar {
  display: flex;
  margin: 24px 80px;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.mobile-sec-nav {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-sec-nav a {
  text-decoration: none;
  color: #000021;
  font-size: 20px;
}

.mobile-sec-nav a:hover {
  text-decoration: underline;
}

.mobile-last-nav {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin: 12px 0;
}

.social-media-icons {
  width: 40px;
  height: 40px;
}

.get-start-btn-mobile button {
  color: #0b0992;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  border-radius: 100px;
  border: 1px solid #0b0992;
  padding: 8px 16px;
  background: transparent;
  cursor: pointer;
}

.mobile-csoiallogin {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.separator--line-nav {
  width: 100%;
  height: 1px;
  background: #ddd;
  width: 250px;
  margin: 30px auto;
  margin-bottom: 0;
}

/* Responsiveness */
@media (max-width: 1518px) {
  .hero-container {
    margin-top: -10px;
  }

  .hero-title {
    font-size: 32px;
    width: 450px;
  }

  .jonah-trade-styles::before {
    height: 60px;
    right: -5px;
    border-radius: 4px;
  }

  .hero-text {
    font-size: 18px;
    margin-bottom: 42px;
  }

  .hero-btn {
    font-size: 18px;
    padding: 12px 24px;
  }

  .hero-img img {
    width: 480px;
  }

  .hero-footer-text {
    margin-top: 60px;
  }

  .hero-footer-text,
  .hero-sub-para {
    font-size: 20px;
  }

  .arrows {
    width: 28px;
    height: 28px;
  }

  .separator {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .about-heading {
    font-size: 36px;
  }

  .about-me {
    padding: 20px 80px;
  }

  .about-me-para {
    font-size: 18px;
  }

  .about-separator {
    margin-top: 5px;
  }

  .strategy-section {
    margin-top: 30px;
  }

  .strategy-container {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .strategy-title {
    font-size: 36px;
  }

  .strategy-container img {
    width: 600px;
  }

  .strategy-content {
    font-size: 18px;
  }

  .working-how {
    font-size: 36px;
  }

  .work-container-support {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .regular-paras-for-work {
    font-size: 18px;
  }

  .how-works-specific {
    font-size: 18px;
  }

  .work-ethics-heading {
    font-size: 20px;
  }

  .offer-container {
    margin-top: 40px;
  }

  .early-bird-offer {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .card-details div {
    font-size: 19px;
  }

  .offer-text {
    font-size: 18px;
  }

  .offer-img img {
    width: 400px;
  }
}

@media (max-width: 1320px) {
  .hero-container {
    justify-content: center;
    gap: 50px;
  }
}

@media (max-width: 1250px) {
  .nav-center-menu,
  .nav-end {
    display: none;
  }

  .nav-hams-menu {
    display: block;
  }
  
  .hero-section {
    margin-top: 20px;
  }

  .jonah-trade-styles::before {
    height: 46px;
    top: -8px;
  }

  .hero-title {
    font-size: 28px;
    width: 400px;
  }

  .background-box {
    top: -12px;
    width: 360px;
    height: 53px;
    margin-bottom: 12px;
  }

  .hero-img img {
    width: 380px;
  }

  .hero-btn {
    font-size: 16px;
    padding: 10px 20px;
  }

  .arrows {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1100px) {
  .hero-container {
    justify-content: flex-start;
  }

  .hero-img {
    display: none;
  }

  .jonah-trade-styles::before {
    height: 60px;
    top: -8px;
  }

  .hero-title {
    font-size: 36px;
    width: 780px;
  }

  .hero-text {
    width: 780px;
    font-size: 24px;
  }

  .background-box {
    top: -4px;
  }

  .hero-footer-text {
    margin-left: 80px;
    margin-right: 80px;
  }

  .strategy-container > div:first-child {
    display: none;
  }
}

@media (max-width: 950px) {
  .hero-title {
    font-size: 28px;
    width: 500px;
  }

  .hero-text {
    width: 500px;
    font-size: 18px;
  }

  .jonah-trade-styles::before {
    height: 46px;
    top: -8px;
  }

  .background-box {
    top: -14px;
  }

  .offer-img {
    display: none;
  }
}

@media (max-width: 820px) {
  .footer-logo {
    width: 100px;
  }

  .social-icons__ {
    width: 24px;
    height: 24px;
  }

  .footer-middle a {
    font-size: 16px;
  }

  .footer-class {
    padding: 12px 80px;
  }
}

@media (max-width: 700px) {
  .main-logo-icon {
    width: 160px;
  }

  .main-nav {
    padding: 20px 30px;
  }

  .mobile-top-bar {
    margin: 20px 30px;
  }

  .mobile-logo-icon {
    width: 160px;
  }

  .hams-sytle, .close-icon {
    width: 32px;
    height: 32px;
  }

  .hero-container {
    margin: 20px 30px;
  }

  .hero-title {
    width: 100%;
  }

  .hero-text {
    width: 100%;
  }

  .hero-footer-text {
    margin-left: 30px;
    margin-right: 30px;
  }

  .separator {
    margin-left: 15px;
    margin-right: 15px;
  }

  .about-me {
    padding-left: 25px;
    padding-right: 25px;
  }

  .strategy-container {
    margin-left: 25px;
    margin-right: 25px;
  }

  .work-container-support {
    margin-left: 25px;
    margin-right: 25px;
  }

  .offer-container {
    margin-left: 25px;
    margin-right: 25px;
  }

  .footer-class {
    padding: 10px 30px;
    flex-direction: column;
    gap: 18px;
  }

  .social-icons__ {
    width: 34px;
    height: 34px;
  }
}

@media(max-width: 600px) {
  .mobile-nav {
    display: flex;
  }
}

@media (max-width: 560px) {
  .close-icon {
    width: 32px;
    height: 32px;
  }

  .social-media-icons {
    width: 32px;
    height: 32px;
  }
}

.hiddenMenu {
  display: none;
}
