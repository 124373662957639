.policy {
  text-align: center;
  margin-top: 76px;
}

.link {
  color: #5652e9;
}

@media(max-width: 600px) {
  .policy {
    margin-top: 32px;
  }
}