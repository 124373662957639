.header {
  width: fit-content;
  margin: auto;
  text-align: center;
  margin-top: 60px;
}

h1 {
  color: #454545;
  font-weight: 200;
}

h4 {
  color: #454545;
  font-weight: 500;
  margin-top: 15px;
}

.authenticateRightShift {
  display: flex;
  margin-right: 15px;
}

.authenticatedButtonContaier {
  color: #000000b2;
  position: absolute;
  left: 68px;
  bottom: 15px;
}

.note {
  width: 529px;
  border: 1px solid #5652e9;
  border-left: 4px solid #5652e9;
  padding: 20px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 10px auto 50px auto;
  color: #00000099;
  font-weight: 500;
}
.note > span {
  color: #5652e9;
}
.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 10px 0px 10px;
  margin: 10px;
}

.smallLine {
  height: 1px;
  background: #000;
  width: 30px;
  opacity: 0.2;
  margin: auto;
}

@media only screen and (max-width: 570px) {
  .authenticatedButtonContaier {
    font-size: 10px;
    position: absolute;
    left: 17px;
    bottom: 5px;
  }
  .note {
    width: 341px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 525px) {
  .container {
    width: 85vw;
    margin-right: 0;
    margin-left: 0;
    padding: 10px 10px 0px 10px;
    margin: 0;
  }

  .authenticatedButtonContaier {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .authenticateRightShift {
    margin: 5px 10px;
  }

  .buttons {
    margin-left: 3vw;
    font-size: 12px;
  }
}
