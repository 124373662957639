.unvail-_card__wrapper {
  display: flex;
  justify-content: space-between;
}

.info-_container__box_ {
  display: flex;
}

.rounded-_box_radio {
  width: 50px;
}

.radio__sphere {
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  top: 4px;
}

.unavailable__cards {
  margin: 16px 40px;
  background: rgba(71, 66, 236, 0.04);
  padding: 25px 28px;
}

.text-_style {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

.type-_text__ {
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}

.balance-_sheet > div:first-child {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.account-_info {
  margin-right: 48px;
}

.unvailable_card-tag {
  text-align: end;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}


@media(max-width: 480px) {
  .account-_info {
    margin-right: 24px;
  }
}

@media(max-width: 418px) {
  .account-_info {
    margin-right: 10px;
  }
}
