.account-section {
  margin-top: 30px;
}

.header-tag {
  text-align: center;
  font-size: 32px;
  color: #454545;
  margin-top: 60px;
}

.hr-line {
  opacity: 0.2;
  width: 32px;
  height: 2px;
  background-color: #000;
  margin: 25px auto;
}

.account-container {
  width: 610px;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  border-radius: 8px;
  padding: 30px 0;
}

.configure-text {
  text-align: center;
  font-size: 24px;
}

.controllers {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 16px;
}

.btns__ {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 8px 12px;
  border: 2px solid white;
  cursor: pointer;
  font-size: 20px;
}

.color-buffer {
  opacity: 0.5;
}

.account-section-error-statement {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.radio {
  width: 8px;
  height: 8px;
  background: #d1d1d1;
  opacity: 1;
  border-radius: 50%;
}

.controlButtons {
  margin: 0 12px;
}

#active-controller {
  border-bottom: 2px solid #4742eccc;
  border-radius: 5px;
  opacity: 1;
}

#active-controller>div {
  opacity: 1;
}

.hr-line--2 {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}

.btns__:hover .color-buffer {
  opacity: 1;
}

.btn__sky {
  background: linear-gradient(90deg,
      rgba(71, 66, 236, 0.9) 0%,
      rgba(26, 22, 222, 0.9) 89.42%),
    #ffffff;
  border: none;
  color: #ffffff;
  display: flex;
  margin: 40px auto;
  padding: 12px 20px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.btn__sky>div:first-child {
  margin-right: 15px;
}

.unauthenticate-broker__inter {
  text-align: end;
  margin: 0 40px;
}

.unauthenticate-broker__inter button {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  background: #fdecec;
  border: 1px solid transparent;
  color: #ef5858;
  border-radius: 4px;
}

.unauthenticate-broker__inter button:hover {
  border: 1px solid #ef5858;
}

.brokerage--Notice {
  margin: 10px 40px;
  padding: 27px 20px;
  border: 1px solid rgba(86, 82, 233, 0.4);
  border-left: 4px solid #5652e9;
  border-radius: 4px;
  margin-top: 16px;
}

.brokerage--Notice div {
  font-weight: 500;
  font-size: 18px;
  color: #5652e9;
}

.brokerage--Notice p {
  font-weight: 500;
  font-size: 18px;
  text-align: start;
  margin-bottom: 0;
  color: #757575;
}

div.account-container div.radio.active-green {
  background: #16d16d;
}

#active-controller.disableNav {
  border-bottom-color: #d9d9d9;
}


@media(max-width: 600px) {
  .header-tag {
    margin-top: 20px;
    font-size: 26px;
  }

  .unauthenticate-broker__inter {
    margin: 0 24px;
  }

  .brokerage--Notice {
    margin: 10px 24px;
    margin-top: 16px;
  }

  .unauthenticate-broker__inter button {
    font-size: 13px;
    padding: 8px 18px;
  }

  .hr-line {
    margin: 18px auto;
  }

  .account-section {
    margin-top: 18px;
  }

  .account-section-error-statement {
    font-size: 14px;
  }

  .account-container {
    width: auto;
    margin: 20px 10px;
  }

  .configure-text {
    font-size: 20px;
  }

  .btns__ {
    font-size: 16px;
  }

  .color-buffer {
    display: flex;
  }

  .btn__sky {
    font-size: 15px;
  }

  .brokerage--Notice p {
    font-size: 16px;
  }
}

@media(max-width: 560px) {
  .header-tag {
    font-size: 20px;
  }

  .hr-line {
    margin: 12px auto;
    width: 20px;
  }
}

@media(max-width: 480px) {
  .unauthenticate-broker__inter {
    margin: 0 18px;
  }

  .brokerage--Notice {
    margin: 10px 18px;
    margin-top: 16px;
  }

  .brokerage--Notice p {
    font-size: 13px;
  }
}