.notificaiton-error-container {
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  margin-bottom: -30px;
}

.error--container {
  border: 1px solid rgba(239, 88, 88, 0.4);
  padding: 24px 20px;
  margin: 8px 18px;
  border-left-width: 4px;
  border-left-color: #ef5858;
  background: #fdecec;
}

.error--name {
  font-weight: 500;
  font-size: 18px;
  color: #ef5858;
  margin-bottom: 10px;
}

.error--solution {
  font-weight: 500;
  font-size: 18px;
  color: #757575;
}

@media(max-width: 600px) {
  .notificaiton-error-container {
    padding: 18px 28px;
  }

  .error--container {
    margin: 8px 0;
  }

  .error--name {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .error--solution {
    font-size: 14px;
  }
}