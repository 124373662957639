.interactive-heading {
  margin: 20px auto;
  padding: 5px 10px;
  color: #0ea554;
  font-size: 20px;
  font-weight: 500;
  background: rgba(22, 209, 109, 0.1);
  width: fit-content;
}

@media(max-width: 600px) {
  .interactive-heading {
    font-size: 17px;
  }
}

@media(max-width: 480px) {
  .interactive-heading {
    font-size: 14px;
  }
}