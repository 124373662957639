.auth-container,
.userProfile,
.logout {
  display: flex;
  color: #454545;
}

.auth-container {
  align-items: center;
}

.userProfile {
  margin-right: 30px;
}

.imgIcons__ {
  margin-right: 10px;
}

.userProfile {
  font-weight: 700;
}

.logout>div:last-child {
  line-height: 24px;
}

.userProfile>div:last-child {
  line-height: 25px;
}

.userProfile,
.logout {
  margin-top: 5px;
}

.logout {
  opacity: 0.6;
  font-weight: 500;
  cursor: pointer;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}


@media(max-width: 600px) {
  .auth-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .userProfile {
    order: 2;
    padding: 12px 16px 8px 12px;
  }

  .logout {
    order: 1;
    padding: 12px 16px 8px 12px;
  }
}