.notifier__ {
  display: flex;
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 24px 30px;
  align-items: baseline;
  padding-right: 0;
  cursor: pointer;
}

.notification--wrapper {
  margin-top: 50px;
  cursor: pointer;
}

.rounded-__box_ {
  position: relative;
  top: -6px;
  margin-right: 8px;
}

.rounded-__box_-ext {
  width: 24px;
  height: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rounded-__box_-int {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
}

.notifier__ > div:last-child {
  margin-left: 24px;
}

.slack-notify p,
.sms-notify p {
  text-align: start;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.slack-notify p span {
  text-decoration: underline;
  cursor: pointer;
}

.anchor-containerd {
  margin-top: -5px;
}

.anchor-containerd button,
.resend-link button {
  background: white;
  border: none;
  font-weight: 500;
  font-size: 14px;
  color: #5652e9;
  text-decoration-line: underline;
  padding-left: 0%;
  cursor: pointer;
}

.enable-button button {
  background: linear-gradient(
      90deg,
      rgba(71, 66, 236, 0.9) 0%,
      rgba(26, 22, 222, 0.9) 89.42%
    ),
    #ffffff;
  border: none;
  color: #ffffff;
  display: flex;
  margin: 30px auto;
  padding: 12px 20px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
}

#timer {
  cursor: text;
}

.invite-send-notify {
  font-weight: 500;
  font-size: 14px;
  color: #0ea554;
  cursor: text;
}

.notifier--active .rounded-__box_ .rounded-__box_-int {
  background: #5652e9;
}

.notifier--active .rounded-__box_ .rounded-__box_-ext {
  border-color: #4742ec;
}

.input--box__ {
  margin-bottom: 5px;
}

.input--box__ input {
  width: 440px;
  height: 46px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-weight: 400;
  font-size: 20px;
  padding: 0 15px;
  margin-left: 0;
  outline: none;
}

.success---message {
  font-weight: 500;
  font-size: 14px;
  color: #0ea554;
  cursor: text;
}

#timer {
  cursor: text;
}


@media(max-width: 600px) {
  .input--box__ {
    margin-top: 10px;
  }
  
  .input--box__ input {
    width: 93%;
  }

  .notifier__ {
    padding: 18px 30px;
  }

  .sms-notify {
    flex-grow: 1;
  }
}

@media(max-width: 490px) {
  .input--box__ input {
    font-size: 16px;
    width: 90%;
  }

  .enable-button button {
    font-size: 14px;
  }
}