.header-text {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.container {
  width: 610px;
  margin: 10px 0;
  padding: 24px 0;
  border-radius: 8px;
  /* border: 1px solid rgba(0, 0, 0, 0.30); */
  position: relative;
}

.back-icon {
  position: relative;
  top: 20px;
  left: 40px;
}

.disconnect-ikbr {
  text-align: end;
  padding: 5px 40px;
}

.disconnect-ikbr button {
  color: #ef5858;
  cursor: pointer;
  border: none;
  border: 1px solid transparent;
  background: #FDECEC;
  font-size: 16px;
  border-radius: 3px;
  padding: 6px 20px;
}

.disconnect-ikbr button:hover {
  border: 1px solid #ef5858;
}

.content-header {
  text-align: center;
  color: #000;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.img-content img {
  width: 60px;
  height: 60px;
}

.divider {
  border-radius: 8px;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
  margin: 24px 0;
}

.status-wrap-container {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 16px 0;
}

.header-wrap-status {
  text-align: center;
}

.header-wrap-status a {
  color: #5652e9;
}

.ikbr_refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.ikbr-logo {
  margin-bottom: 12px;
}

.download-btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4742ec;
  text-decoration: underline;
  cursor: pointer;
}

.status-wrapper {
  display: flex;
  padding: 23px 24px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: #fff;
}

.title {
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.active-dot {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #d1d1d1;
}

.green-color {
  background: #16d16d;
}

.red-color {
  background-color: #ef5858;
}

.login-button-container {
  display: flex;
  justify-content: flex-end;
}

.login-button-container button {
  border: none;
  outline: none;
  display: flex;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--purple, #5652e9);
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.login-button-container button:hover {
  background: #4742ec;
}

@media (max-width: 600px) {
  .container {
    width: 400px;
  }

  .content-header {
    font-size: 18px;
  }

  .img-content img {
    width: 48px;
    height: 48px;
  }

  .status-wrap-container {
    padding: 0 30px;
  }

  .title {
    font-size: 16px;
    gap: 12px;
  }

  .divider {
    margin: 20px 0;
  }

  .status-wrapper {
    padding: 18px;
  }

  .active-dot {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 450px) {
  .container {
    width: 300px;
  }

  .img-content img {
    width: 32px;
    height: 32px;
  }

  .content-header {
    font-size: 15px;
  }

  .title {
    font-size: 13px;
  }

  .status-wrap-container {
    padding: 0 20px;
    gap: 18px;
  }

  .status-wrapper {
    padding: 12px 10px;
  }

  .login-button-container button {
    font-size: 13px;
  }
}