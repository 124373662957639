h4 {
  font-size: 18px;
  text-align: center;
  color: #454545;
  margin-bottom: 8px;
}

p {
  color: rgba(69, 69, 69, 0.6);
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
}

.bottom-disclaimer {
  margin-top: 52px;
  margin-bottom: 0px;
  padding: 0px 10px;
}

.broker-container {
  padding: 0px 40px;
}

.broker-container > div {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 22px 30px;
  margin: 20px 0;
  display: flex;
}

.sphere {
  position: relative;
  background: none;
  border: none;
  display: block;
  margin-right: 25px;
}

.round-ext {
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.round-int {
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 100;
}

.broker-content {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.cardHovering:hover {
  box-shadow: 0px 8px 27px rgba(0, 0, 0, 0.1),
    0px 2px 4.3875px rgba(0, 0, 0, 0.05);
  border-color: transparent;
  cursor: pointer;
}

.cardHovering:hover .round-ext {
  background: #7d7d7d;
  border-color: transparent;
}

.cardHovering:hover .round-int {
  background: white;
  opacity: 1;
}

.cardHovering.remove-hover {
  cursor: not-allowed;
  background: rgba(71, 66, 236, 0.04);
  border-color: transparent;
}

.cardHovering.remove-hover:hover {
  box-shadow: none;
  /* border: 0.5px solid rgba(0, 0, 0, 0.3); */
  background: rgba(71, 66, 236, 0.04);
}

.cardHovering.remove-hover:hover .round-ext {
  background: transparent;
  border: 0.5px solid #454545;
}

#active-radio {
  border-color: #4742ec;
}

#active-radio .sphere .round-ext {
  border-color: #4742ec;
}

#active-radio .sphere .round-int {
  opacity: 1;
  background: #4742ec;
}

#active-radio:hover .round-ext {
  background: white;
}

button.btn__sky.disabled-btn {
  background: #ccc;
  cursor: not-allowed;
}

@media(max-width: 600px) {
  h4 {
    font-size: 16px;
  }

  .sphere {
    margin-right: 16px;
  }

  .broker-content {
    font-size: 14px;
  }

  .round-ext {
    width: 14px;
    height: 14px;
  }

  .round-int {
    width: 9px;
    height: 9px;
  }

  .broker-container {
    padding: 0 25px;
  }
}