.box-container {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 0;
  padding: 20px 28px;
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  /* align-items: center; */
  align-items: baseline;
  gap: 20px;
  margin: 30px auto;
}

.box-header-tag__ {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.box-header-tag__1 {
  color: rgba(69, 69, 69, 0.6);
  text-align: center;
  font-family: DM Sans;
  font-size: 15px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.amt {
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 7px;
}

.subsc {
  color: var(--purple, #5652e9);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.plan-controller {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.subs-button__ {
  border: none;
  outline: none;
  color: blue;
  /* border: 1px solid red; */
  background: #fff;
  text-decoration: underline;
  /* color: red; */
  cursor: pointer;
  /* padding: 6px 0; */
  /* border-radius: 2px; */
  /* width: 170px; */
  text-align: end;
}

/* Updated styles */
.main-billing-container {
  margin: 30px auto;
  width: 1165px;
}

.bill-info-container {
  display: flex;
  justify-content: space-between;
}

.billing-info {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 22px 30px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  width: 500px;
}

.billing-info-_1__ {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
}

.billing-info-_1__>div:first-child {
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.billing-info-_1__>div:last-child {
  color: var(--purple, #5652e9);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.billing-info--__--1-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;
}

.billing-info--__--1-2>div:last-child {
  color: #0fa958;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cancel-plan-btn {
  padding: 5px 16px;
  color: #ef5858;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid #ef5858;
  background: #fff;
  cursor: pointer;
}

.cancel-plan-btn:hover {
  background: #ef5858;
  color: white;
}

.billing-card-info {
  display: flex;
  gap: 60px;
  justify-content: space-between;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 22px 36px;
  width: 500px;
}

.card-details--poitn .card-num {
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.card-details--poitn .card-mail {
  color: rgba(0, 0, 0, 0.5);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

/* .card-details--poitn > div:nth-child(3) {
  color: rgba(0, 0, 0, 0.5);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */

.bills-_i_nfo {
  display: flex;
  gap: 24px;
  align-items: center;
}

.edit-card-details button {
  background: transparent;
  border: none;
  outline: none;
  color: var(--purple, #5652e9);
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.billing-centricit {
  margin: 30px 0;
  margin-bottom: 18px;
}

.billing-centricit-head {
  color: #454545;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.billing-centricit-content {
  color: rgba(69, 69, 69, 0.6);
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.red-alert {
  color: red;
}

.green-alert {
  color: green;
}


@media(max-width: 600px) {
  .main-billing-container {
    width: auto;
  }

  .bill-info-container {
    flex-direction: column;
    gap: 30px;
  }

  .billing-info {
    width: auto;
    margin: 0 30px;
  }

  .billing-card-info {
    width: auto;
    margin: 0 30px;
  }

  .billing-info-_1__>div:first-child {
    font-size: 24px;
  }

  .cancel-plan-btn {
    font-size: 14px;
    padding: 3px 12px;
  }

  .billing-centricit {
    margin: 24px 30px;
  }

  .billing-card-info {
    gap: 0;
  }

  .table-wrapper {
    overflow: hidden;
    width: 100%;
    width: -webkit-fill-available;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media(max-width: 540px) {

  .billing-info,
  .billing-card-info {
    padding: 16px 20px;
  }

  .card-details--poitn .card-num {
    font-size: 16px;
  }

  .bill-info-container {
    gap: 20px;
  }

  .card-details--poitn .card-mail {
    font-size: 14px;
  }

  .billing-info-_1__>div:first-child {
    font-size: 18px;
  }

  .billing-info-_1__>div:last-child,
  .billing-info--__--1-2>div:last-child {
    font-size: 14px;
  }

  .bills-_i_nfo {
    gap: 16px;
  }
}

@media(max-width: 460px) {
  .billing-info,
  .billing-card-info {
    margin: 0 12px;
  }

  .billing-centricit {
    margin-left: 12px;
    margin-right: 12px;
  }

  .table-wrapper {
    padding-right: 12px;
    padding-left: 12px;
  }
}