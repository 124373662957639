.same-para {
  font-family: "DM Sans", sans-serif !important;
  color: #000;
  text-align: start;
}

.policy-container {
  text-align: center;
  margin: 20px 0;
  font-size: 32px;
  color: #333;
}

.container {
  width: 85vw;
  margin: 20px auto;
}

.ol-container>li {
  margin-bottom: 10px;
}

ol>li::marker {
  font-weight: bold;
}

.ol-container>li::marker {
  font-weight: bold;
  font-size: 20px;
}