/* Invoice Table */
.table-header {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  border-radius: 4px 4px 0px 0px;
  background: #fcfcfc;
  padding: 24px 30px;
}

.table-header .invoice-number,
.table-header .billing-date,
.table-header .billing-status,
.table-header .amount,
.table-header .subs-plaan {
  font-weight: 700;
}

.invoice-number input {
  width: 18px;
  height: 18px;
}

.table-rows {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
}

.invoice-number {
  width: 140px;
  display: flex;
  gap: 20px;
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.billing-date {
  width: 100px;
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table-rows .billing-status {
  width: 50px;
  border-radius: 2px;
  text-align: center;
  padding: 2px 8px;
  align-items: center;
  gap: 8px;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.amount {
  width: 100px;
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paid {
  color: var(--green, #0ea554);
  background: rgba(14, 165, 84, 0.1);
}

.unpaid {
  color: #ef5858;
  background: rgba(239, 88, 88, 0.1);
}

.subs-plaan {
  width: 155px;
  color: rgba(0, 0, 0, 0.7);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subs-inv-download {
  width: 100px;
  color: var(--purple, #5652e9);
  text-align: right;
  font-variant-numeric: lining-nums proportional-nums;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.table-rows:hover {
  background: #f5f5f5;
}

/* Pagination */
.pagination-nav {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 10px;
}

.pages {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid var(--L1, #ededfd);
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.current-page,
.pages:hover {
  border-color: #5652e9;
  color: #5652e9;
}

@media(max-width: 600px) {

  .table-header,
  .table-rows {
    width: 950px;
  }

  .actual-box-wrapper {
    width: 100%;
    width: -webkit-fill-available;
    overflow-x: auto;
  }
  
  .pagination-nav {
    padding: 0 30px;
  }

}