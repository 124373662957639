@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}
html{
  scroll-behavior: smooth;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  text-align: center;
  margin-top: 60px;
}

h1 {
  color: #454545;
  font-weight: 200;
}

h4 {
  color: #454545;
  font-weight: 500;
  margin-top: 15px;
}

.authenticateRightShift {
  display: flex;
  margin-right: 15px;
}

.authenticatedButtonContaier {
  color: #000000b2;
  position: absolute;
  left: 68px;
  bottom: 15px;
}

.note {
  width: 529px;
  border: 1px solid #5652e9;
  border-left: 4px solid #5652e9;
  padding: 20px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 10px auto 50px auto;
  color: #00000099;
  font-weight: 500;
}
.note > span {
  color: #5652e9;
}
.container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  padding: 10px 10px 0px 10px;
  margin: 10px;
}

.smallLine {
  height: 1px;
  background: #000;
  width: 30px;
  opacity: 0.2;
  margin: auto;
}

@media only screen and (max-width: 570px) {
  .authenticatedButtonContaier {
    font-size: 10px;
    position: absolute;
    left: 17px;
    bottom: 5px;
  }
  .note {
    width: 341px;
    font-size: 12px;
  }
}

@media only screen and (max-width: 525px) {
  .container {
    width: 85vw;
    margin-right: 0;
    margin-left: 0;
    padding: 10px 10px 0px 10px;
    margin: 0;
  }

  .authenticatedButtonContaier {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .authenticateRightShift {
    margin: 5px 10px;
  }

  .buttons {
    margin-left: 3vw;
    font-size: 12px;
  }
}

* {
  font-family: "DM Sans", sans-serif;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1080px;
  margin: 40px auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #fff;
  z-index: 10;
}

.main-header-tabs__ {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  /* gap: 120px; */
}

.mobile-view-nav-bar-hidden {
  display: none;
}

.logo {
  cursor: pointer;
}

.hams-wrapper {
  display: none;
}

.cross-icon__2 {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

@media(max-width: 600px) {
  .main-header {
    width: auto;
    margin: 0;
    padding: 24px 30px;
  }

  .main-header-tabs__ {
    display: none;
  }

  .hams-wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
  }

  .hamburger-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .logo img {
    width: 140px !important;
  }

  .mobile-view-nav-bar-hidden {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    width: -webkit-fill-available;
    height: 100vh;
    background: #fff;
    z-index: 120;
  }

  .mobile-header-menu__ {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 2px;
    gap: 2px;
  }

  .break--line__2 {
    width: 100%;
    height: 1px;
    background: #ddd;
    margin: 10px 0;
  }

  .display-class {
    display: block;
  }

  .mobile-nav-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 30px;
  }
}
.icon-container {
    display: flex;
    align-items: center;
    padding: 12px 16px 8px 12px;
    border: 2px solid white;
    opacity: 0.5;
    cursor: pointer;
}



.icon-container:hover {
    opacity: 1;
}

.icon-text {
    font-size: 20px;
    margin-left: 16px;
}

.icon-active {
    border-bottom: 2px solid #4742ECCC;
    border-radius: 5px;
    opacity: 1;

}

@media(max-width: 600px) {
    .icon-container:nth-child(2) {
        margin: 0;
    }

    .icon-active {
        background-color: #f8f8f8;
    }
}
.menu-container {
    display: flex;
    justify-content: space-between;
}

@media(max-width: 600px) {
    .menu-container {
        flex-direction: column;
        grid-gap: 5px;
        gap: 5px;
    }
}
.auth-container,
.userProfile,
.logout {
  display: flex;
  color: #454545;
}

.auth-container {
  align-items: center;
}

.userProfile {
  margin-right: 30px;
}

.imgIcons__ {
  margin-right: 10px;
}

.userProfile {
  font-weight: 700;
}

.logout>div:last-child {
  line-height: 24px;
}

.userProfile>div:last-child {
  line-height: 25px;
}

.userProfile,
.logout {
  margin-top: 5px;
}

.logout {
  opacity: 0.6;
  font-weight: 500;
  cursor: pointer;
}

.avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}


@media(max-width: 600px) {
  .auth-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .userProfile {
    order: 2;
    padding: 12px 16px 8px 12px;
  }

  .logout {
    order: 1;
    padding: 12px 16px 8px 12px;
  }
}
.Popup_overlay__paGNF {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.20);
}

.Popup_popup-container__zQRVm {
    width: 400px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 105;
    padding: 60px 40px;
    border-radius: 4px;
    animation: Popup_scaleUpAndDown__2EUT6 0.5s;
}

.Popup_close-icon__2u46l {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.Popup_popup-icon__3CDpr {
    text-align: center;
    margin-bottom: 20px;
}

.Popup_popup-status__3LW2Z {
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    margin-bottom: 6px;
}

.Popup_success__1SBxx {
    color: #5652E9;
}

.Popup_error__3AY4X {
    color: #EF5858;
}

.Popup_popup-message__1sskL {
    color: #000;
    text-align: center;
    margin: 0 auto;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 300px;
}

.Popup_plan-details__1C948 {
    font-weight: 700;
}

/* For Download App styles are */
.Popup_popup-modal-header__Umm2- {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
}

.Popup_popup-modal-app-container__27lFD {
    display: flex;
    justify-content: center;
    grid-gap: 100px;
    gap: 100px;
    text-align: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 50px 0;
    margin-bottom: 10px;
}

.Popup_os-icons__3iJ5w {
    margin: 5px 0;
    margin-bottom: 12px;
}

.Popup_popup-modal-app-container__27lFD a {
    color: #4742EC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
}

.Popup_bold-overlay__2YzgG {
    background: rgba(0, 0, 0, 0.40);
}

@media(max-width: 520px) {
    .Popup_popup-container__zQRVm {
        width: 320px;
        padding: 40px 20px;
    }

    .Popup_popup-status__3LW2Z {
        font-size: 22px;
    }
}

/* Popup Animation */
@keyframes Popup_scaleUpAndDown__2EUT6 {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Helper Classes */
.Popup_hidden__1ivON {
    display: none;
}
.account-section {
  margin-top: 30px;
}

.header-tag {
  text-align: center;
  font-size: 32px;
  color: #454545;
  margin-top: 60px;
}

.hr-line {
  opacity: 0.2;
  width: 32px;
  height: 2px;
  background-color: #000;
  margin: 25px auto;
}

.account-container {
  width: 610px;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  border-radius: 8px;
  padding: 30px 0;
}

.configure-text {
  text-align: center;
  font-size: 24px;
}

.controllers {
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 16px;
}

.btns__ {
  margin: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 8px 12px;
  border: 2px solid white;
  cursor: pointer;
  font-size: 20px;
}

.color-buffer {
  opacity: 0.5;
}

.account-section-error-statement {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.radio {
  width: 8px;
  height: 8px;
  background: #d1d1d1;
  opacity: 1;
  border-radius: 50%;
}

.controlButtons {
  margin: 0 12px;
}

#active-controller {
  border-bottom: 2px solid #4742eccc;
  border-radius: 5px;
  opacity: 1;
}

#active-controller>div {
  opacity: 1;
}

.hr-line--2 {
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}

.btns__:hover .color-buffer {
  opacity: 1;
}

.btn__sky {
  background: linear-gradient(90deg,
      rgba(71, 66, 236, 0.9) 0%,
      rgba(26, 22, 222, 0.9) 89.42%),
    #ffffff;
  border: none;
  color: #ffffff;
  display: flex;
  margin: 40px auto;
  padding: 12px 20px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.btn__sky>div:first-child {
  margin-right: 15px;
}

.unauthenticate-broker__inter {
  text-align: end;
  margin: 0 40px;
}

.unauthenticate-broker__inter button {
  font-weight: 500;
  font-size: 16px;
  padding: 8px 24px;
  background: #fdecec;
  border: 1px solid transparent;
  color: #ef5858;
  border-radius: 4px;
}

.unauthenticate-broker__inter button:hover {
  border: 1px solid #ef5858;
}

.brokerage--Notice {
  margin: 10px 40px;
  padding: 27px 20px;
  border: 1px solid rgba(86, 82, 233, 0.4);
  border-left: 4px solid #5652e9;
  border-radius: 4px;
  margin-top: 16px;
}

.brokerage--Notice div {
  font-weight: 500;
  font-size: 18px;
  color: #5652e9;
}

.brokerage--Notice p {
  font-weight: 500;
  font-size: 18px;
  text-align: start;
  margin-bottom: 0;
  color: #757575;
}

div.account-container div.radio.active-green {
  background: #16d16d;
}

#active-controller.disableNav {
  border-bottom-color: #d9d9d9;
}


@media(max-width: 600px) {
  .header-tag {
    margin-top: 20px;
    font-size: 26px;
  }

  .unauthenticate-broker__inter {
    margin: 0 24px;
  }

  .brokerage--Notice {
    margin: 10px 24px;
    margin-top: 16px;
  }

  .unauthenticate-broker__inter button {
    font-size: 13px;
    padding: 8px 18px;
  }

  .hr-line {
    margin: 18px auto;
  }

  .account-section {
    margin-top: 18px;
  }

  .account-section-error-statement {
    font-size: 14px;
  }

  .account-container {
    width: auto;
    margin: 20px 10px;
  }

  .configure-text {
    font-size: 20px;
  }

  .btns__ {
    font-size: 16px;
  }

  .color-buffer {
    display: flex;
  }

  .btn__sky {
    font-size: 15px;
  }

  .brokerage--Notice p {
    font-size: 16px;
  }
}

@media(max-width: 560px) {
  .header-tag {
    font-size: 20px;
  }

  .hr-line {
    margin: 12px auto;
    width: 20px;
  }
}

@media(max-width: 480px) {
  .unauthenticate-broker__inter {
    margin: 0 18px;
  }

  .brokerage--Notice {
    margin: 10px 18px;
    margin-top: 16px;
  }

  .brokerage--Notice p {
    font-size: 13px;
  }
}
.notificaiton-error-container {
  display: flex;
  flex-direction: column;
  padding: 24px 40px;
  margin-bottom: -30px;
}

.error--container {
  border: 1px solid rgba(239, 88, 88, 0.4);
  padding: 24px 20px;
  margin: 8px 18px;
  border-left-width: 4px;
  border-left-color: #ef5858;
  background: #fdecec;
}

.error--name {
  font-weight: 500;
  font-size: 18px;
  color: #ef5858;
  margin-bottom: 10px;
}

.error--solution {
  font-weight: 500;
  font-size: 18px;
  color: #757575;
}

@media(max-width: 600px) {
  .notificaiton-error-container {
    padding: 18px 28px;
  }

  .error--container {
    margin: 8px 0;
  }

  .error--name {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .error--solution {
    font-size: 14px;
  }
}
.notifier__ {
  display: flex;
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 24px 30px;
  align-items: baseline;
  padding-right: 0;
  cursor: pointer;
}

.notification--wrapper {
  margin-top: 50px;
  cursor: pointer;
}

.rounded-__box_ {
  position: relative;
  top: -6px;
  margin-right: 8px;
}

.rounded-__box_-ext {
  width: 24px;
  height: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rounded-__box_-int {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-radius: 50%;
}

.notifier__ > div:last-child {
  margin-left: 24px;
}

.slack-notify p,
.sms-notify p {
  text-align: start;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.slack-notify p span {
  text-decoration: underline;
  cursor: pointer;
}

.anchor-containerd {
  margin-top: -5px;
}

.anchor-containerd button,
.resend-link button {
  background: white;
  border: none;
  font-weight: 500;
  font-size: 14px;
  color: #5652e9;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  padding-left: 0%;
  cursor: pointer;
}

.enable-button button {
  background: linear-gradient(
      90deg,
      rgba(71, 66, 236, 0.9) 0%,
      rgba(26, 22, 222, 0.9) 89.42%
    ),
    #ffffff;
  border: none;
  color: #ffffff;
  display: flex;
  margin: 30px auto;
  padding: 12px 20px;
  font-size: 18px;
  margin-bottom: 16px;
  border-radius: 2px;
  cursor: pointer;
}

#timer {
  cursor: text;
}

.invite-send-notify {
  font-weight: 500;
  font-size: 14px;
  color: #0ea554;
  cursor: text;
}

.notifier--active .rounded-__box_ .rounded-__box_-int {
  background: #5652e9;
}

.notifier--active .rounded-__box_ .rounded-__box_-ext {
  border-color: #4742ec;
}

.input--box__ {
  margin-bottom: 5px;
}

.input--box__ input {
  width: 440px;
  height: 46px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-weight: 400;
  font-size: 20px;
  padding: 0 15px;
  margin-left: 0;
  outline: none;
}

.success---message {
  font-weight: 500;
  font-size: 14px;
  color: #0ea554;
  cursor: text;
}

#timer {
  cursor: text;
}


@media(max-width: 600px) {
  .input--box__ {
    margin-top: 10px;
  }
  
  .input--box__ input {
    width: 93%;
  }

  .notifier__ {
    padding: 18px 30px;
  }

  .sms-notify {
    flex-grow: 1;
  }
}

@media(max-width: 490px) {
  .input--box__ input {
    font-size: 16px;
    width: 90%;
  }

  .enable-button button {
    font-size: 14px;
  }
}
.configured_notificaiton {
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  color: #454545;
  margin-top: 25px;
  margin-bottom: 6px;
}

.notificaiton-received {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(69, 69, 69, 0.6);
  margin-bottom: 38px;
}

.notifying--card {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 14px 20px;
  margin: 20px 40px;
}

.notifying--card > div:first-child {
  display: flex;
  align-items: center;
}

.notifying--card > div:first-child > div:first-child {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
}

.notifying--card > div:last-child {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.notifying--card > div:last-child span {
  text-decoration: underline;
}

.notifying--card > div.mobile {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 20px;
}

.edit-notification {
  margin: 10px 40px;
  text-align: end;
}

.edit-notification button {
  background: none;
  border: none;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  color: #5652e9;
  cursor: pointer;
}

.Notification_policy__AMzdf {
  text-align: center;
  margin-top: 76px;
}

.Notification_link__2OVqN {
  color: #5652e9;
}

@media(max-width: 600px) {
  .Notification_policy__AMzdf {
    margin-top: 32px;
  }
}
.unvail-_card__wrapper {
  display: flex;
  justify-content: space-between;
}

.info-_container__box_ {
  display: flex;
}

.rounded-_box_radio {
  width: 50px;
}

.radio__sphere {
  width: 18px;
  height: 18px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: relative;
  top: 4px;
}

.unavailable__cards {
  margin: 16px 40px;
  background: rgba(71, 66, 236, 0.04);
  padding: 25px 28px;
}

.text-_style {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
}

.type-_text__ {
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}

.balance-_sheet > div:first-child {
  font-weight: 700;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.account-_info {
  margin-right: 48px;
}

.unvailable_card-tag {
  text-align: end;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}


@media(max-width: 480px) {
  .account-_info {
    margin-right: 24px;
  }
}

@media(max-width: 418px) {
  .account-_info {
    margin-right: 10px;
  }
}

.interactive-heading {
  margin: 20px auto;
  padding: 5px 10px;
  color: #0ea554;
  font-size: 20px;
  font-weight: 500;
  background: rgba(22, 209, 109, 0.1);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media(max-width: 600px) {
  .interactive-heading {
    font-size: 17px;
  }
}

@media(max-width: 480px) {
  .interactive-heading {
    font-size: 14px;
  }
}
.available__cards {
  margin: 16px 40px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 25px 28px;
  transition: all 0.3s ease-in-out;
}

.avail-_card__wrapper {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.radio_box--rounded {
  width: 50px;
}

.spherical_radio-_btn {
  width: 18px;
  height: 18px;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 4px;
}

.spherical_radio-_btn::before {
  z-index: 2;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.available_card-tag {
  text-align: end;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}

.available__cards:hover {
  box-shadow: 0px 8px 27px rgba(0, 0, 0, 0.1),
    0px 2px 4.3875px rgba(0, 0, 0, 0.05);
  border-color: transparent;
}

.available__cards:hover .spherical_radio-_btn::before {
  transform: scale(1.3, 1.3);
}

.checkbox_svg__icon {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.active-available-card .spherical_radio-_btn::before {
  z-index: -1;
}

.active-available-card .checkbox_svg__icon {
  opacity: 1;
}

.account-confirmation {
  text-align: end;
  margin-top: 24px;
}

.account-confirmation button {
  background: #5652e9;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.subscribed-as {
  margin-top: 24px;
  text-align: end;
  font-weight: 700;
  font-size: 14px;
  color: #5652e9;
}

.active-available-card {
  border-color: #5652e9;
}

.checkbox_svg__icon {
  background: #5652e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -4px;
  left: -5px;
}

@media(max-width: 600px) {
  .available__cards {
    margin: 10px 24px;
    padding: 16px 20px;
    margin-bottom: 16px;
  }

  .text-_style,
  .balance-_sheet>div:first-child {
    font-size: 16px;
  }

  .account-confirmation {
    margin-top: 16px;
  }

  .account-confirmation button {
    font-size: 13px;
    padding: 5px 12px;
    border-radius: 2px;
  }

  .radio_box--rounded {
    width: 38px;
  }
}

@media(max-width: 480px) {
  .available__cards {
    margin: 8px 18px;
    padding: 12px 16px;
    margin-bottom: 16px;
  }
}

@media(max-width: 418px) {
  
}
h4 {
  font-size: 18px;
  text-align: center;
  color: #454545;
  margin-bottom: 8px;
}

p {
  color: rgba(69, 69, 69, 0.6);
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
}

.bottom-disclaimer {
  margin-top: 52px;
  margin-bottom: 0px;
  padding: 0px 10px;
}

.broker-container {
  padding: 0px 40px;
}

.broker-container > div {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 22px 30px;
  margin: 20px 0;
  display: flex;
}

.sphere {
  position: relative;
  background: none;
  border: none;
  display: block;
  margin-right: 25px;
}

.round-ext {
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.round-int {
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 100;
}

.broker-content {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.7);
}

.cardHovering:hover {
  box-shadow: 0px 8px 27px rgba(0, 0, 0, 0.1),
    0px 2px 4.3875px rgba(0, 0, 0, 0.05);
  border-color: transparent;
  cursor: pointer;
}

.cardHovering:hover .round-ext {
  background: #7d7d7d;
  border-color: transparent;
}

.cardHovering:hover .round-int {
  background: white;
  opacity: 1;
}

.cardHovering.remove-hover {
  cursor: not-allowed;
  background: rgba(71, 66, 236, 0.04);
  border-color: transparent;
}

.cardHovering.remove-hover:hover {
  box-shadow: none;
  /* border: 0.5px solid rgba(0, 0, 0, 0.3); */
  background: rgba(71, 66, 236, 0.04);
}

.cardHovering.remove-hover:hover .round-ext {
  background: transparent;
  border: 0.5px solid #454545;
}

#active-radio {
  border-color: #4742ec;
}

#active-radio .sphere .round-ext {
  border-color: #4742ec;
}

#active-radio .sphere .round-int {
  opacity: 1;
  background: #4742ec;
}

#active-radio:hover .round-ext {
  background: white;
}

button.btn__sky.disabled-btn {
  background: #ccc;
  cursor: not-allowed;
}

@media(max-width: 600px) {
  h4 {
    font-size: 16px;
  }

  .sphere {
    margin-right: 16px;
  }

  .broker-content {
    font-size: 14px;
  }

  .round-ext {
    width: 14px;
    height: 14px;
  }

  .round-int {
    width: 9px;
    height: 9px;
  }

  .broker-container {
    padding: 0 25px;
  }
}
/* Authenticaiton */
.authenticaiton-container {
  padding: 20px 40px;
  padding-bottom: 0;
}

.auth-head-container {
  position: relative;
  margin-top: 20px;
}

.back-icon {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.auth-head--content > div {
  text-align: center;
}

.auth-head--content > div:first-child {
  font-weight: 500;
  font-size: 18px;
  color: #454545;
  margin-bottom: 6px;
}

.auth-head--content > div:last-child {
  font-weight: 400;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.6);
  margin-bottom: 20px;
}

.username--input > input,
.pass-input > input {
  display: block;
  outline: none;
  font-weight: 400;
  font-size: 20px;
  width: 100%;
  padding: 20px 24px;
  box-sizing: border-box;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.username--input {
  margin-bottom: 16px;
}

.pass-input {
  margin-bottom: 24px;
}

.preload--wait {
  text-align: center;
  margin: 80px auto;
  padding-top: 20px;
  width: 400px;
  font-size: 20px;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

/**
 * ==============================================
 * Dot Spin
 * ==============================================
 */
.dot-spin {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: transparent;
  color: transparent;
  box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
    18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    0 18px 0 0 rgba(152, 128, 255, 0),
    -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
    -18px 0 0 0 rgba(152, 128, 255, 0),
    -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
  animation: dot-spin 1.5s infinite linear;
  margin-top: 75px;
  left: 200px;
}

@keyframes dot-spin {
  0%,
  100% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
      18px 0 0 0 #9880ff, 12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  12.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 0 #9880ff, 18px 0 0 0 #9880ff,
      12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  25% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0), 18px 0 0 0 #9880ff,
      12.727926px 12.727926px 0 0 #9880ff, 0 18px 0 0 #9880ff,
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  37.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0), 12.727926px 12.727926px 0 0 #9880ff,
      0 18px 0 0 #9880ff, -12.727926px 12.727926px 0 0 #9880ff,
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  50% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0), 0 18px 0 0 #9880ff,
      -12.727926px 12.727926px 0 0 #9880ff, -18px 0 0 0 #9880ff,
      -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
  }
  62.5% {
    box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0), -12.727926px 12.727926px 0 0 #9880ff,
      -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
  }
  75% {
    box-shadow: 0 -18px 0 0 #9880ff,
      12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 0 #9880ff, -12.727926px -12.727926px 0 0 #9880ff;
  }
  87.5% {
    box-shadow: 0 -18px 0 0 #9880ff, 12.727926px -12.727926px 0 0 #9880ff,
      18px 0 0 -5px rgba(152, 128, 255, 0),
      12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      0 18px 0 -5px rgba(152, 128, 255, 0),
      -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
      -18px 0 0 -5px rgba(152, 128, 255, 0),
      -12.727926px -12.727926px 0 0 #9880ff;
  }
}

.IBKRStatus_header-text__1QLLb {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.IBKRStatus_container__rBZba {
  width: 610px;
  margin: 10px 0;
  padding: 24px 0;
  border-radius: 8px;
  /* border: 1px solid rgba(0, 0, 0, 0.30); */
  position: relative;
}

.IBKRStatus_back-icon__oWmT2 {
  position: relative;
  top: 20px;
  left: 40px;
}

.IBKRStatus_disconnect-ikbr__2Q9Je {
  text-align: end;
  padding: 5px 40px;
}

.IBKRStatus_disconnect-ikbr__2Q9Je button {
  color: #ef5858;
  cursor: pointer;
  border: none;
  border: 1px solid transparent;
  background: #FDECEC;
  font-size: 16px;
  border-radius: 3px;
  padding: 6px 20px;
}

.IBKRStatus_disconnect-ikbr__2Q9Je button:hover {
  border: 1px solid #ef5858;
}

.IBKRStatus_content-header__1vnEX {
  text-align: center;
  color: #000;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.IBKRStatus_img-content__31DhE img {
  width: 60px;
  height: 60px;
}

.IBKRStatus_divider__1uaXk {
  border-radius: 8px;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
  margin: 24px 0;
}

.IBKRStatus_status-wrap-container__2BPFn {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  margin: 16px 0;
}

.IBKRStatus_header-wrap-status__f6WiQ {
  text-align: center;
}

.IBKRStatus_header-wrap-status__f6WiQ a {
  color: #5652e9;
}

.IBKRStatus_ikbr_refresh__1TuZR {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.IBKRStatus_ikbr-logo__1WYzg {
  margin-bottom: 12px;
}

.IBKRStatus_download-btn__3gg1f {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4742ec;
  text-decoration: underline;
  cursor: pointer;
}

.IBKRStatus_status-wrapper__3utMU {
  display: flex;
  padding: 23px 24px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  background: #fff;
}

.IBKRStatus_title__KhedC {
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.IBKRStatus_active-dot__1nCMx {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: #d1d1d1;
}

.IBKRStatus_green-color__3ul9x {
  background: #16d16d;
}

.IBKRStatus_red-color__2x_HI {
  background-color: #ef5858;
}

.IBKRStatus_login-button-container__1EFCr {
  display: flex;
  justify-content: flex-end;
}

.IBKRStatus_login-button-container__1EFCr button {
  border: none;
  outline: none;
  display: flex;
  padding: 8px 24px;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  border-radius: 4px;
  background: #5652e9;
  background: var(--purple, #5652e9);
  color: #fff;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.IBKRStatus_login-button-container__1EFCr button:hover {
  background: #4742ec;
}

@media (max-width: 600px) {
  .IBKRStatus_container__rBZba {
    width: 400px;
  }

  .IBKRStatus_content-header__1vnEX {
    font-size: 18px;
  }

  .IBKRStatus_img-content__31DhE img {
    width: 48px;
    height: 48px;
  }

  .IBKRStatus_status-wrap-container__2BPFn {
    padding: 0 30px;
  }

  .IBKRStatus_title__KhedC {
    font-size: 16px;
    grid-gap: 12px;
    gap: 12px;
  }

  .IBKRStatus_divider__1uaXk {
    margin: 20px 0;
  }

  .IBKRStatus_status-wrapper__3utMU {
    padding: 18px;
  }

  .IBKRStatus_active-dot__1nCMx {
    width: 8px;
    height: 8px;
  }
}

@media (max-width: 450px) {
  .IBKRStatus_container__rBZba {
    width: 300px;
  }

  .IBKRStatus_img-content__31DhE img {
    width: 32px;
    height: 32px;
  }

  .IBKRStatus_content-header__1vnEX {
    font-size: 15px;
  }

  .IBKRStatus_title__KhedC {
    font-size: 13px;
  }

  .IBKRStatus_status-wrap-container__2BPFn {
    padding: 0 20px;
    grid-gap: 18px;
    gap: 18px;
  }

  .IBKRStatus_status-wrapper__3utMU {
    padding: 12px 10px;
  }

  .IBKRStatus_login-button-container__1EFCr button {
    font-size: 13px;
  }
}
.ConfiguredBilling_box-container__1_m20 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 0;
  padding: 20px 28px;
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
  /* align-items: center; */
  align-items: baseline;
  grid-gap: 20px;
  gap: 20px;
  margin: 30px auto;
}

.ConfiguredBilling_box-header-tag____3EXmi {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ConfiguredBilling_box-header-tag__1__1folB {
  color: rgba(69, 69, 69, 0.6);
  text-align: center;
  font-family: DM Sans;
  font-size: 15px;
  margin-top: 6px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ConfiguredBilling_amt__3E4z8 {
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  margin-bottom: 7px;
}

.ConfiguredBilling_subsc__34RWx {
  color: #5652e9;
  color: var(--purple, #5652e9);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}

.ConfiguredBilling_plan-controller__33nde {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.ConfiguredBilling_subs-button____3bV1W {
  border: none;
  outline: none;
  color: blue;
  /* border: 1px solid red; */
  background: #fff;
  text-decoration: underline;
  /* color: red; */
  cursor: pointer;
  /* padding: 6px 0; */
  /* border-radius: 2px; */
  /* width: 170px; */
  text-align: end;
}

/* Updated styles */
.ConfiguredBilling_main-billing-container__2qcck {
  margin: 30px auto;
  width: 1165px;
}

.ConfiguredBilling_bill-info-container__39viO {
  display: flex;
  justify-content: space-between;
}

.ConfiguredBilling_billing-info__JUY-H {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  padding: 22px 30px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  width: 500px;
}

.ConfiguredBilling_billing-info-_1____xj_GF {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  justify-content: space-between;
}

.ConfiguredBilling_billing-info-_1____xj_GF>div:first-child {
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ConfiguredBilling_billing-info-_1____xj_GF>div:last-child {
  color: #5652e9;
  color: var(--purple, #5652e9);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ConfiguredBilling_billing-info--__--1-2__15Laq {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: end;
}

.ConfiguredBilling_billing-info--__--1-2__15Laq>div:last-child {
  color: #0fa958;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ConfiguredBilling_cancel-plan-btn__3y7ds {
  padding: 5px 16px;
  color: #ef5858;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 2px;
  border: 1px solid #ef5858;
  background: #fff;
  cursor: pointer;
}

.ConfiguredBilling_cancel-plan-btn__3y7ds:hover {
  background: #ef5858;
  color: white;
}

.ConfiguredBilling_billing-card-info__9c9As {
  display: flex;
  grid-gap: 60px;
  gap: 60px;
  justify-content: space-between;
  border-radius: 4px;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 22px 36px;
  width: 500px;
}

.ConfiguredBilling_card-details--poitn__3SsqT .ConfiguredBilling_card-num__PZysl {
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 4px;
}

.ConfiguredBilling_card-details--poitn__3SsqT .ConfiguredBilling_card-mail__1aDWX {
  color: rgba(0, 0, 0, 0.5);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

/* .card-details--poitn > div:nth-child(3) {
  color: rgba(0, 0, 0, 0.5);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
} */

.ConfiguredBilling_bills-_i_nfo__1GIMk {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  align-items: center;
}

.ConfiguredBilling_edit-card-details__1SCX3 button {
  background: transparent;
  border: none;
  outline: none;
  color: #5652e9;
  color: var(--purple, #5652e9);
  text-align: right;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.ConfiguredBilling_billing-centricit__2qevb {
  margin: 30px 0;
  margin-bottom: 18px;
}

.ConfiguredBilling_billing-centricit-head__2eRY4 {
  color: #454545;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6px;
}

.ConfiguredBilling_billing-centricit-content__uuGG5 {
  color: rgba(69, 69, 69, 0.6);
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ConfiguredBilling_red-alert__19QXm {
  color: red;
}

.ConfiguredBilling_green-alert__3OBNf {
  color: green;
}


@media(max-width: 600px) {
  .ConfiguredBilling_main-billing-container__2qcck {
    width: auto;
  }

  .ConfiguredBilling_bill-info-container__39viO {
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
  }

  .ConfiguredBilling_billing-info__JUY-H {
    width: auto;
    margin: 0 30px;
  }

  .ConfiguredBilling_billing-card-info__9c9As {
    width: auto;
    margin: 0 30px;
  }

  .ConfiguredBilling_billing-info-_1____xj_GF>div:first-child {
    font-size: 24px;
  }

  .ConfiguredBilling_cancel-plan-btn__3y7ds {
    font-size: 14px;
    padding: 3px 12px;
  }

  .ConfiguredBilling_billing-centricit__2qevb {
    margin: 24px 30px;
  }

  .ConfiguredBilling_billing-card-info__9c9As {
    grid-gap: 0;
    gap: 0;
  }

  .ConfiguredBilling_table-wrapper__TGKzW {
    overflow: hidden;
    width: 100%;
    width: -webkit-fill-available;
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media(max-width: 540px) {

  .ConfiguredBilling_billing-info__JUY-H,
  .ConfiguredBilling_billing-card-info__9c9As {
    padding: 16px 20px;
  }

  .ConfiguredBilling_card-details--poitn__3SsqT .ConfiguredBilling_card-num__PZysl {
    font-size: 16px;
  }

  .ConfiguredBilling_bill-info-container__39viO {
    grid-gap: 20px;
    gap: 20px;
  }

  .ConfiguredBilling_card-details--poitn__3SsqT .ConfiguredBilling_card-mail__1aDWX {
    font-size: 14px;
  }

  .ConfiguredBilling_billing-info-_1____xj_GF>div:first-child {
    font-size: 18px;
  }

  .ConfiguredBilling_billing-info-_1____xj_GF>div:last-child,
  .ConfiguredBilling_billing-info--__--1-2__15Laq>div:last-child {
    font-size: 14px;
  }

  .ConfiguredBilling_bills-_i_nfo__1GIMk {
    grid-gap: 16px;
    gap: 16px;
  }
}

@media(max-width: 460px) {
  .ConfiguredBilling_billing-info__JUY-H,
  .ConfiguredBilling_billing-card-info__9c9As {
    margin: 0 12px;
  }

  .ConfiguredBilling_billing-centricit__2qevb {
    margin-left: 12px;
    margin-right: 12px;
  }

  .ConfiguredBilling_table-wrapper__TGKzW {
    padding-right: 12px;
    padding-left: 12px;
  }
}
/* Invoice Table */
.Invoice_table-header__2YQAU {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  border-radius: 4px 4px 0px 0px;
  background: #fcfcfc;
  padding: 24px 30px;
}

.Invoice_table-header__2YQAU .Invoice_invoice-number__2Lh0Q,
.Invoice_table-header__2YQAU .Invoice_billing-date__Xvwp3,
.Invoice_table-header__2YQAU .Invoice_billing-status__3w1KB,
.Invoice_table-header__2YQAU .Invoice_amount__1iUTV,
.Invoice_table-header__2YQAU .Invoice_subs-plaan__2x4W- {
  font-weight: 700;
}

.Invoice_invoice-number__2Lh0Q input {
  width: 18px;
  height: 18px;
}

.Invoice_table-rows__1moWF {
  display: flex;
  justify-content: space-between;
  padding: 24px 30px;
}

.Invoice_invoice-number__2Lh0Q {
  width: 140px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  color: rgba(0, 0, 0, 0.85);
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Invoice_billing-date__Xvwp3 {
  width: 100px;
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Invoice_table-rows__1moWF .Invoice_billing-status__3w1KB {
  width: 50px;
  border-radius: 2px;
  text-align: center;
  padding: 2px 8px;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Invoice_amount__1iUTV {
  width: 100px;
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Invoice_paid__1n_Qj {
  color: #0ea554;
  color: var(--green, #0ea554);
  background: rgba(14, 165, 84, 0.1);
}

.Invoice_unpaid__mFKOP {
  color: #ef5858;
  background: rgba(239, 88, 88, 0.1);
}

.Invoice_subs-plaan__2x4W- {
  width: 155px;
  color: rgba(0, 0, 0, 0.7);
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Invoice_subs-inv-download__3fQRU {
  width: 100px;
  color: #5652e9;
  color: var(--purple, #5652e9);
  text-align: right;
  font-feature-settings: lining-nums proportional-nums;
  font-variant-numeric: lining-nums proportional-nums;
  text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.08);
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.Invoice_table-rows__1moWF:hover {
  background: #f5f5f5;
}

/* Pagination */
.Invoice_pagination-nav__1trZo {
  display: flex;
  grid-gap: 12px;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 10px;
}

.Invoice_pages__ZkfCV {
  width: 30px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #ededfd;
  border: 1px solid var(--L1, #ededfd);
  background: #fff;
  background: var(--White, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Invoice_current-page__2hrJ2,
.Invoice_pages__ZkfCV:hover {
  border-color: #5652e9;
  color: #5652e9;
}

@media(max-width: 600px) {

  .Invoice_table-header__2YQAU,
  .Invoice_table-rows__1moWF {
    width: 950px;
  }

  .Invoice_actual-box-wrapper__yJTFH {
    width: 100%;
    width: -webkit-fill-available;
    overflow-x: auto;
  }
  
  .Invoice_pagination-nav__1trZo {
    padding: 0 30px;
  }

}
.SetupBilling_plans-container__ITuYx {
  padding: 20px 64px;
  padding-top: 0;
  margin-top: 30px;
}

.SetupBilling_plan-headers__scjqx {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SetupBilling_plans-container__ITuYx h2 {
  color: #333;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.SetupBilling_plan-wrapper__3sRw9 {
  display: flex;
  grid-gap: 40px;
  gap: 40px;
  margin-top: 60px;
  justify-content: center;
  margin-bottom: 20px;
}

.SetupBilling_plan-info__2fzd7 {
  width: 300px;
  background: #fff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 35px 30px;
  height: -webkit-max-content;
  height: max-content;
}

.SetupBilling_plan-info__2fzd7:hover {
  box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.2);
}

.SetupBilling_plan-name__aWjC2 {
  color: #333;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 5px;
}

.SetupBilling_break-line__3W0Dd {
  width: 44px;
  height: 3px;
  margin: 2px auto 16px auto;
  background: #5652e9;
  border-radius: 2px;
}

.SetupBilling_plan-price__3eQp0 {
  color: #333;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* letter-spacing: 2.4px; */
  text-align: center;
}

.SetupBilling_plan-type__2jLu2 {
  color: #333;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.SetupBilling_hr-line__2duC0 {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid #d9d9d9;
  margin: 30px 0;
}

.SetupBilling_service__158kg {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.SetupBilling_services-wrapper__3Jzw6 {
  display: flex;
  flex-direction: column;
  grid-gap: 14px;
  gap: 14px;
  margin-bottom: 30px;
}

.SetupBilling_service__158kg>span:first-child {
  line-height: 1px;
}

.SetupBilling_submit-btn__-yUXV {
  text-align: center;
}

.SetupBilling_submit-btn__-yUXV button {
  border: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 2px;
  background: #5652e9;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 40px;
  cursor: pointer;
}

.SetupBilling_submit-btn__-yUXV button:hover {
  background: rgba(26, 22, 222, 0.8);
}

@media(max-width: 600px) {
  .SetupBilling_plan-wrapper__3sRw9 {
    flex-wrap: wrap;
  }

  .SetupBilling_plan-name__aWjC2 {
    font-size: 20px;
  }

  .SetupBilling_plan-price__3eQp0 {
    font-size: 25px;
  }

  .SetupBilling_break-line__3W0Dd {
    margin-bottom: 10px;
  }

  .SetupBilling_plan-info__2fzd7 {
    padding: 24px 0;
  }

  .SetupBilling_hr-line__2duC0 {
    margin: 24px 0;
  }

  .SetupBilling_plan-wrapper__3sRw9 {
    margin-top: 40px;
  }

  .SetupBilling_plans-container__ITuYx {
    margin-top: 12px;
  }

  .SetupBilling_plan-headers__scjqx {
    font-size: 14px;
    font-weight: 700;
  }
}
.Policy_same-para__3lcmE {
  font-family: "DM Sans", sans-serif !important;
  color: #000;
  text-align: start;
}

.Policy_policy-container__17FsQ {
  text-align: center;
  margin: 20px 0;
  font-size: 32px;
  color: #333;
}

.Policy_container__3IXdH {
  width: 85vw;
  margin: 20px auto;
}

.Policy_ol-container__3Kvb8>li {
  margin-bottom: 10px;
}

ol>li::marker {
  font-weight: bold;
}

.Policy_ol-container__3Kvb8>li::marker {
  font-weight: bold;
  font-size: 20px;
}
.Dashboard_dashboard-header__3q6zV {
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  width: 500px;
  margin: 0 auto;
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  letter-spacing: -0.176px;
}

.Dashboard_acc-status__IRk3z {
  font-weight: 700;
}

.Dashboard_configuration-container__25ENH {
  width: 600px;
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.Dashboard_configure-tab__2WTlZ {
  border: 1px solid rgba(69, 69, 69, 0.5);
  padding: 23px 20px;
}

.Dashboard_outline-success__1JkXp {
  border-color: #4742ec;
}

.Dashboard_outline-fail__1sBpT {
  border-color: rgba(69, 69, 69, 0.5);
}

.Dashboard_box-header__U6J_W {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.Dashboard_configured-info__3wz_3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Dashboard_configure-metadata-1__33Bhz {
  width: 170px;
}

.Dashboard_configure-metadata-2__2WaiR {
  width: 145px;
}

.Dashboard_configure-metadata-3__2pU9G {
  width: 156px;
}

.Dashboard_configure-status__2CXQL {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.Dashboard_configure-dot__2UY4o {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #ddd;
}

.Dashboard_configure-props__y_FL- {
  color: rgba(0, 0, 0, 0.7);
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Dashboard_configure-value__21j6L {
  color: rgba(69, 69, 69, 0.5);
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Dashboard_action-btn__1lsls {
  color: #fff;
  text-decoration: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  border-radius: 2px;
  background: linear-gradient(90deg, #4742ec 0%, #1a16de 92.34%);
  color: #fff;
  font-family: DM Sans;
  font-size: 13.942px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.Dashboard_auth-props__1h6Qx {
  color: #000;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.Dashboard_auth--2__3X779 {
  margin-top: 10px;
}

.Dashboard_green-dot__3nUks {
  background: #16d16d;
}

.Dashboard_red-dot__1wqc4 {
  background: #ef5858;
}

@media(max-width: 600px) {
  .Dashboard_configuration-container__25ENH {
    width: auto;
    padding: 5px 10px;
  }
}

@media(max-width: 560px) {

  .Dashboard_box-header__U6J_W,
  .Dashboard_configure-props__y_FL-,
  .Dashboard_auth-props__1h6Qx {
    font-size: 14px;
  }

  
}

@media(max-width: 520px) {
  .Dashboard_configure-tab__2WTlZ {
    padding: 10px 14px;
  }

  .Dashboard_configure-dot__2UY4o {
    width: 5px;
    height: 5px;
  }

  .Dashboard_configure-status__2CXQL {
    grid-gap: 4px;
    gap: 4px;
  }

  .Dashboard_dashboard-header__3q6zV {
    width: auto;
    text-align: center;
    margin: 10px;
  }
}
.Landing_select-font__2NS-i {
  font-family: "Lato", sans-serif;
}

.Landing_top-bar__2_79D {
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  padding: 20px 72px;
  background: #d2d2f0;
}

.Landing_main-nav__3_VvE {
  display: flex;
  justify-content: space-between;
  background: #fff;
  align-items: center;
  padding: 24px 80px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

.Landing_nav-hams-menu__2JXwt {
  display: none;
  cursor: pointer;
}

.Landing_nav-center-menu__gJ5YP {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
}

.Landing_hams-sytle__RthZD {
  width: 48px;
  height: 48px;
}

.Landing_nav-center-menu__gJ5YP a {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}

.Landing_nav-center-menu__gJ5YP a:hover {
  text-decoration: underline;
}

.Landing_nav-end__2ostC {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.Landing_get-start-btn-cont__1pnt5 {
  margin-right: 16px;
}

.Landing_get-start-btn-cont__1pnt5 button {
  color: #0b0992;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  border-radius: 100px;
  border: 1px solid #0b0992;
  padding: 8px 16px;
  background: transparent;
  cursor: pointer;
}

.Landing_hero-section__2LDD1 {
  margin-top: 60px;
  position: relative;
}

/* .background-box {
  border-radius: 8px;
  background: rgba(210, 210, 240, 0.4);
  position: absolute;
  top: 30px;
  left: 0;
  z-index: -1;
  width: 534px;
  height: 83px;
} */

.Landing_hero-container__2R_75 {
  display: flex;
  justify-content: space-around;
  grid-gap: 120px;
  gap: 120px;
  align-items: center;
  margin: 30px 110px;
}

.Landing_hero-title__1eFJz {
  color: #000021;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 620px;
  margin-bottom: 18px;
}

.Landing_hero-text__19L3w {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  text-align: start;
  /* 31.392px */
  width: 620px;
  margin-bottom: 60px;
}

.Landing_hero-img__2XELP img {
  width: 680px;
}

.Landing_hero-btn__3x9xi {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #0b0992;
  background: #0b0992;
  box-shadow: 4px 8px 5.2px 0px rgba(210, 209, 231, 0.3),
    1px 3px 1.1px 0px rgba(210, 209, 231, 0.5),
    4px 8px 4px 0px rgba(210, 209, 231, 0.2) inset;
  color: #fefefe;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.Landing_arrows__dAbqc {
  width: 32px;
  height: 32px;
}

.Landing_hero-btn__3x9xi:hover {
  grid-gap: 16px;
  gap: 16px;
  border: 1px solid #2422b7;
  background: #2422b7;
}

.Landing_hero-footer-text__1NDc- {
  margin-top: 90px;
  margin-bottom: 25px;
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.Landing_hero-sub-para__1ycoo {
  color: #9ba6e7;
  font-family: Lato;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.Landing_separator__2AlQ7 {
  height: 1px;
  background: #ddd;
  margin: 30px 80px;
  margin-top: 60px;
  margin-bottom: 20px;
}

.Landing_about-me__2AwTO {
  padding: 30px 80px;
}

.Landing_about-heading__19Rwf {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.Landing_about-me-para__14rpe {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.Landing_about-separator__3SrX- {
  margin-top: 20px;
}

.Landing_strategy-section__34OZw {
  margin-top: 60px;
}

.Landing_strategy-container__3BW9R {
  display: flex;
  margin: 20px 80px;
  margin-bottom: 40px;
}

.Landing_strategy-container__3BW9R > div:first-child {
  text-align: center;
  display: flex;
}

.Landing_strategy-container__3BW9R img {
  width: 720px;
}

.Landing_strategy-title__3T19g {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 18px;
}

.Landing_strategy-content__20it6 {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.Landing_working-how__18pke {
  color: #000021;
  font-family: Lato;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Landing_regular-paras-for-work__38fZE {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.Landing_how-works-specific__3kl0Z {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130.8%;
}

.Landing_work-ethics-heading__1Q8Ln {
  text-align: start;
  color: #000021;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.8%;
}

.Landing_work-ordered-list__1mM2w {
  color: #000021;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.8%;
  /* 31.392px */
}

.Landing_work-container-support__1UYkG {
  margin: 20px 80px;
  margin-bottom: 30px;
}

.Landing_early-bird-offer__M-cBu {
  color: #000021;
  font-family: Lato;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 45px;
}

.Landing_offer-container__1iQAA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 80px;
  grid-gap: 100px;
  gap: 100px;
}

.Landing_offer-cards__1eq5- {
  display: flex;
  grid-gap: 48px;
  gap: 48px;
  margin-bottom: 36px;
}

.Landing_card-details__2C0Y- div {
  color: #000021;
  font-family: Lato;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.8%;
  /* 47.088px */
}

.Landing_offer-text__3sVkG {
  color: #000;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 48px;
}

.Landing_offer-bird__2yY-8,
.Landing_offer-img__WIqy2 {
  flex: 1 1;
}

.Landing_offer-img__WIqy2 img {
  width: 500px;
}

.Landing_footer-class__1NmB3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 20px 80px;
  background: #d2d2f0;
  margin-top: 60px;
}

.Landing_footer-logo__37GyB {
  width: 140px;
}

.Landing_footer-middle__2BCWI {
  display: flex;
  grid-gap: 22px;
  gap: 22px;
}

.Landing_social-icons__7Xh3J {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.Landing_footer-middle__2BCWI a {
  color: #000021;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

/* Trade Like Jonah */
.Landing_jonah-trade-styles__YmIF0 {
  position: relative;
}

.Landing_jonah-trade-styles__YmIF0::before {
  content: '';
  width: 150%;
  height: 80px;
  background: rgba(210, 210, 240, 0.4);
  position: absolute;
  top: -14px;
  right: -10px;
  z-index: -1;
  border-radius: 8px;
}

/* Mobile Nav */
.Landing_mobile-nav__2igfc {
  position: fixed;
  width: 100vw;
  width: -webkit-fill-available;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  /* justify-content: space-between; */
}

.Landing_mobile-top-bar__1MXV7 {
  display: flex;
  margin: 24px 80px;
  justify-content: space-between;
  align-items: center;
}

.Landing_close-icon__3v12Z {
  cursor: pointer;
  width: 48px;
  height: 48px;
}

.Landing_mobile-sec-nav__2DPiy {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.Landing_mobile-sec-nav__2DPiy a {
  text-decoration: none;
  color: #000021;
  font-size: 20px;
}

.Landing_mobile-sec-nav__2DPiy a:hover {
  text-decoration: underline;
}

.Landing_mobile-last-nav__2LQDV {
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  align-items: center;
  margin: 12px 0;
}

.Landing_social-media-icons__1y91F {
  width: 40px;
  height: 40px;
}

.Landing_get-start-btn-mobile__3KcBi button {
  color: #0b0992;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
  border-radius: 100px;
  border: 1px solid #0b0992;
  padding: 8px 16px;
  background: transparent;
  cursor: pointer;
}

.Landing_mobile-csoiallogin__2Dwdz {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
}

.Landing_separator--line-nav__3CmW9 {
  width: 100%;
  height: 1px;
  background: #ddd;
  width: 250px;
  margin: 30px auto;
  margin-bottom: 0;
}

/* Responsiveness */
@media (max-width: 1518px) {
  .Landing_hero-container__2R_75 {
    margin-top: -10px;
  }

  .Landing_hero-title__1eFJz {
    font-size: 32px;
    width: 450px;
  }

  .Landing_jonah-trade-styles__YmIF0::before {
    height: 60px;
    right: -5px;
    border-radius: 4px;
  }

  .Landing_hero-text__19L3w {
    font-size: 18px;
    margin-bottom: 42px;
  }

  .Landing_hero-btn__3x9xi {
    font-size: 18px;
    padding: 12px 24px;
  }

  .Landing_hero-img__2XELP img {
    width: 480px;
  }

  .Landing_hero-footer-text__1NDc- {
    margin-top: 60px;
  }

  .Landing_hero-footer-text__1NDc-,
  .Landing_hero-sub-para__1ycoo {
    font-size: 20px;
  }

  .Landing_arrows__dAbqc {
    width: 28px;
    height: 28px;
  }

  .Landing_separator__2AlQ7 {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .Landing_about-heading__19Rwf {
    font-size: 36px;
  }

  .Landing_about-me__2AwTO {
    padding: 20px 80px;
  }

  .Landing_about-me-para__14rpe {
    font-size: 18px;
  }

  .Landing_about-separator__3SrX- {
    margin-top: 5px;
  }

  .Landing_strategy-section__34OZw {
    margin-top: 30px;
  }

  .Landing_strategy-container__3BW9R {
    margin-top: 0;
    margin-bottom: 40px;
  }

  .Landing_strategy-title__3T19g {
    font-size: 36px;
  }

  .Landing_strategy-container__3BW9R img {
    width: 600px;
  }

  .Landing_strategy-content__20it6 {
    font-size: 18px;
  }

  .Landing_working-how__18pke {
    font-size: 36px;
  }

  .Landing_work-container-support__1UYkG {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .Landing_regular-paras-for-work__38fZE {
    font-size: 18px;
  }

  .Landing_how-works-specific__3kl0Z {
    font-size: 18px;
  }

  .Landing_work-ethics-heading__1Q8Ln {
    font-size: 20px;
  }

  .Landing_offer-container__1iQAA {
    margin-top: 40px;
  }

  .Landing_early-bird-offer__M-cBu {
    font-size: 36px;
    margin-bottom: 40px;
  }

  .Landing_card-details__2C0Y- div {
    font-size: 19px;
  }

  .Landing_offer-text__3sVkG {
    font-size: 18px;
  }

  .Landing_offer-img__WIqy2 img {
    width: 400px;
  }
}

@media (max-width: 1320px) {
  .Landing_hero-container__2R_75 {
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
  }
}

@media (max-width: 1250px) {
  .Landing_nav-center-menu__gJ5YP,
  .Landing_nav-end__2ostC {
    display: none;
  }

  .Landing_nav-hams-menu__2JXwt {
    display: block;
  }
  
  .Landing_hero-section__2LDD1 {
    margin-top: 20px;
  }

  .Landing_jonah-trade-styles__YmIF0::before {
    height: 46px;
    top: -8px;
  }

  .Landing_hero-title__1eFJz {
    font-size: 28px;
    width: 400px;
  }

  .Landing_background-box__3C6BU {
    top: -12px;
    width: 360px;
    height: 53px;
    margin-bottom: 12px;
  }

  .Landing_hero-img__2XELP img {
    width: 380px;
  }

  .Landing_hero-btn__3x9xi {
    font-size: 16px;
    padding: 10px 20px;
  }

  .Landing_arrows__dAbqc {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 1100px) {
  .Landing_hero-container__2R_75 {
    justify-content: flex-start;
  }

  .Landing_hero-img__2XELP {
    display: none;
  }

  .Landing_jonah-trade-styles__YmIF0::before {
    height: 60px;
    top: -8px;
  }

  .Landing_hero-title__1eFJz {
    font-size: 36px;
    width: 780px;
  }

  .Landing_hero-text__19L3w {
    width: 780px;
    font-size: 24px;
  }

  .Landing_background-box__3C6BU {
    top: -4px;
  }

  .Landing_hero-footer-text__1NDc- {
    margin-left: 80px;
    margin-right: 80px;
  }

  .Landing_strategy-container__3BW9R > div:first-child {
    display: none;
  }
}

@media (max-width: 950px) {
  .Landing_hero-title__1eFJz {
    font-size: 28px;
    width: 500px;
  }

  .Landing_hero-text__19L3w {
    width: 500px;
    font-size: 18px;
  }

  .Landing_jonah-trade-styles__YmIF0::before {
    height: 46px;
    top: -8px;
  }

  .Landing_background-box__3C6BU {
    top: -14px;
  }

  .Landing_offer-img__WIqy2 {
    display: none;
  }
}

@media (max-width: 820px) {
  .Landing_footer-logo__37GyB {
    width: 100px;
  }

  .Landing_social-icons____3StiH {
    width: 24px;
    height: 24px;
  }

  .Landing_footer-middle__2BCWI a {
    font-size: 16px;
  }

  .Landing_footer-class__1NmB3 {
    padding: 12px 80px;
  }
}

@media (max-width: 700px) {
  .Landing_main-logo-icon__2zB7n {
    width: 160px;
  }

  .Landing_main-nav__3_VvE {
    padding: 20px 30px;
  }

  .Landing_mobile-top-bar__1MXV7 {
    margin: 20px 30px;
  }

  .Landing_mobile-logo-icon__3BYrx {
    width: 160px;
  }

  .Landing_hams-sytle__RthZD, .Landing_close-icon__3v12Z {
    width: 32px;
    height: 32px;
  }

  .Landing_hero-container__2R_75 {
    margin: 20px 30px;
  }

  .Landing_hero-title__1eFJz {
    width: 100%;
  }

  .Landing_hero-text__19L3w {
    width: 100%;
  }

  .Landing_hero-footer-text__1NDc- {
    margin-left: 30px;
    margin-right: 30px;
  }

  .Landing_separator__2AlQ7 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .Landing_about-me__2AwTO {
    padding-left: 25px;
    padding-right: 25px;
  }

  .Landing_strategy-container__3BW9R {
    margin-left: 25px;
    margin-right: 25px;
  }

  .Landing_work-container-support__1UYkG {
    margin-left: 25px;
    margin-right: 25px;
  }

  .Landing_offer-container__1iQAA {
    margin-left: 25px;
    margin-right: 25px;
  }

  .Landing_footer-class__1NmB3 {
    padding: 10px 30px;
    flex-direction: column;
    grid-gap: 18px;
    gap: 18px;
  }

  .Landing_social-icons____3StiH {
    width: 34px;
    height: 34px;
  }
}

@media(max-width: 600px) {
  .Landing_mobile-nav__2igfc {
    display: flex;
  }
}

@media (max-width: 560px) {
  .Landing_close-icon__3v12Z {
    width: 32px;
    height: 32px;
  }

  .Landing_social-media-icons__1y91F {
    width: 32px;
    height: 32px;
  }
}

.Landing_hiddenMenu__3jahn {
  display: none;
}

