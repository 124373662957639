@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

* {
  font-family: "DM Sans", sans-serif;
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1080px;
  margin: 40px auto;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background: #fff;
  z-index: 10;
}

.main-header-tabs__ {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  /* gap: 120px; */
}

.mobile-view-nav-bar-hidden {
  display: none;
}

.logo {
  cursor: pointer;
}

.hams-wrapper {
  display: none;
}

.cross-icon__2 {
  width: 26px;
  height: 26px;
  cursor: pointer;
}

@media(max-width: 600px) {
  .main-header {
    width: auto;
    margin: 0;
    padding: 24px 30px;
  }

  .main-header-tabs__ {
    display: none;
  }

  .hams-wrapper {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
  }

  .hamburger-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .logo img {
    width: 140px !important;
  }

  .mobile-view-nav-bar-hidden {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    width: -webkit-fill-available;
    height: 100vh;
    background: #fff;
    z-index: 120;
  }

  .mobile-header-menu__ {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .break--line__2 {
    width: 100%;
    height: 1px;
    background: #ddd;
    margin: 10px 0;
  }

  .display-class {
    display: block;
  }

  .mobile-nav-header-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 30px;
  }
}