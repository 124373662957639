.plans-container {
  padding: 20px 64px;
  padding-top: 0;
  margin-top: 30px;
}

.plan-headers {
  color: #454545;
  text-align: center;
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.plans-container h2 {
  color: #333;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.plan-wrapper {
  display: flex;
  gap: 40px;
  margin-top: 60px;
  justify-content: center;
  margin-bottom: 20px;
}

.plan-info {
  width: 300px;
  background: #fff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.1);
  padding: 35px 30px;
  height: max-content;
}

.plan-info:hover {
  box-shadow: 0px 11px 30px 0px rgba(0, 0, 0, 0.2);
}

.plan-name {
  color: #333;
  font-family: DM Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 5px;
}

.break-line {
  width: 44px;
  height: 3px;
  margin: 2px auto 16px auto;
  background: #5652e9;
  border-radius: 2px;
}

.plan-price {
  color: #333;
  font-family: DM Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* letter-spacing: 2.4px; */
  text-align: center;
}

.plan-type {
  color: #333;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.hr-line {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom: 1px solid #d9d9d9;
  margin: 30px 0;
}

.service {
  display: flex;
  align-items: center;
  gap: 10px;
}

.services-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 30px;
}

.service>span:first-child {
  line-height: 1px;
}

.submit-btn {
  text-align: center;
}

.submit-btn button {
  border: none;
  outline: none;
  border: 1px solid transparent;
  border-radius: 2px;
  background: #5652e9;
  color: #fff;
  text-transform: uppercase;
  padding: 12px 40px;
  cursor: pointer;
}

.submit-btn button:hover {
  background: rgba(26, 22, 222, 0.8);
}

@media(max-width: 600px) {
  .plan-wrapper {
    flex-wrap: wrap;
  }

  .plan-name {
    font-size: 20px;
  }

  .plan-price {
    font-size: 25px;
  }

  .break-line {
    margin-bottom: 10px;
  }

  .plan-info {
    padding: 24px 0;
  }

  .hr-line {
    margin: 24px 0;
  }

  .plan-wrapper {
    margin-top: 40px;
  }

  .plans-container {
    margin-top: 12px;
  }

  .plan-headers {
    font-size: 14px;
    font-weight: 700;
  }
}