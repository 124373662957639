.overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.20);
}

.popup-container {
    width: 400px;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 105;
    padding: 60px 40px;
    border-radius: 4px;
    animation: scaleUpAndDown 0.5s;
}

.close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
}

.popup-icon {
    text-align: center;
    margin-bottom: 20px;
}

.popup-status {
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    text-align: center;
    margin-bottom: 6px;
}

.success {
    color: #5652E9;
}

.error {
    color: #EF5858;
}

.popup-message {
    color: #000;
    text-align: center;
    margin: 0 auto;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    width: 300px;
}

.plan-details {
    font-weight: 700;
}

/* For Download App styles are */
.popup-modal-header {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-bottom: 30px;
}

.popup-modal-app-container {
    display: flex;
    justify-content: center;
    gap: 100px;
    text-align: center;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 50px 0;
    margin-bottom: 10px;
}

.os-icons {
    margin: 5px 0;
    margin-bottom: 12px;
}

.popup-modal-app-container a {
    color: #4742EC;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
}

.bold-overlay {
    background: rgba(0, 0, 0, 0.40);
}

@media(max-width: 520px) {
    .popup-container {
        width: 320px;
        padding: 40px 20px;
    }

    .popup-status {
        font-size: 22px;
    }
}

/* Popup Animation */
@keyframes scaleUpAndDown {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

/* Helper Classes */
.hidden {
    display: none;
}