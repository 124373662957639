.icon-container {
    display: flex;
    align-items: center;
    padding: 12px 16px 8px 12px;
    border: 2px solid white;
    opacity: 0.5;
    cursor: pointer;
}



.icon-container:hover {
    opacity: 1;
}

.icon-text {
    font-size: 20px;
    margin-left: 16px;
}

.icon-active {
    border-bottom: 2px solid #4742ECCC;
    border-radius: 5px;
    opacity: 1;

}

@media(max-width: 600px) {
    .icon-container:nth-child(2) {
        margin: 0;
    }

    .icon-active {
        background-color: #f8f8f8;
    }
}