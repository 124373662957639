.available__cards {
  margin: 16px 40px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 25px 28px;
  transition: all 0.3s ease-in-out;
}

.avail-_card__wrapper {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.radio_box--rounded {
  width: 50px;
}

.spherical_radio-_btn {
  width: 18px;
  height: 18px;
  transition: all 0.3s ease-in-out;
  position: relative;
  top: 4px;
}

.spherical_radio-_btn::before {
  z-index: 2;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease-in-out;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.available_card-tag {
  text-align: end;
  margin-top: 24px;
  font-weight: 700;
  font-size: 14px;
  color: rgba(69, 69, 69, 0.5);
}

.available__cards:hover {
  box-shadow: 0px 8px 27px rgba(0, 0, 0, 0.1),
    0px 2px 4.3875px rgba(0, 0, 0, 0.05);
  border-color: transparent;
}

.available__cards:hover .spherical_radio-_btn::before {
  transform: scale(1.3, 1.3);
}

.checkbox_svg__icon {
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.active-available-card .spherical_radio-_btn::before {
  z-index: -1;
}

.active-available-card .checkbox_svg__icon {
  opacity: 1;
}

.account-confirmation {
  text-align: end;
  margin-top: 24px;
}

.account-confirmation button {
  background: #5652e9;
  border-radius: 4px;
  border: 1px solid transparent;
  outline: none;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}

.subscribed-as {
  margin-top: 24px;
  text-align: end;
  font-weight: 700;
  font-size: 14px;
  color: #5652e9;
}

.active-available-card {
  border-color: #5652e9;
}

.checkbox_svg__icon {
  background: #5652e9;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -4px;
  left: -5px;
}

@media(max-width: 600px) {
  .available__cards {
    margin: 10px 24px;
    padding: 16px 20px;
    margin-bottom: 16px;
  }

  .text-_style,
  .balance-_sheet>div:first-child {
    font-size: 16px;
  }

  .account-confirmation {
    margin-top: 16px;
  }

  .account-confirmation button {
    font-size: 13px;
    padding: 5px 12px;
    border-radius: 2px;
  }

  .radio_box--rounded {
    width: 38px;
  }
}

@media(max-width: 480px) {
  .available__cards {
    margin: 8px 18px;
    padding: 12px 16px;
    margin-bottom: 16px;
  }
}

@media(max-width: 418px) {
  
}