.menu-container {
    display: flex;
    justify-content: space-between;
}

@media(max-width: 600px) {
    .menu-container {
        flex-direction: column;
        gap: 5px;
    }
}